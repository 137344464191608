import React from "react"

import Headtagmod from '../components/headtagmod'

export default () => (
<div>
  <Headtagmod />
  <div className="l-wrapper__root">
    <div className="l-maincontents">
      404
    </div>
    <div className="l-footer">
      <p className="c-copyright">© 2020 Misono IT Services G.K.</p>
    </div>
  </div>
</div>
)

